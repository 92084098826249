<template>
  <div class="reclaiming">
    <Reclaiming></Reclaiming>
  </div>
</template>

<script>
import Reclaiming from "@/components/Reclaiming-Content.vue";
export default {
  name: "Home",

  components: {
    Reclaiming: Reclaiming
  },
  mounted() {},
  methods: {}
};
</script>
